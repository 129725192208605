<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-3">
            <div class="p-5 bg-white rounded-lg shadow-lg">
                <ul class="list-none">
                    <li
                        v-for="(item, index) in tabs"
                        :key="index"
                        class="py-3 text-center cursor-pointer"
                        :class="
                            item.value[0] === choseTab[0]
                                ? 'bg-red-500 bg-opacity-20 rounded-lg'
                                : ''
                        "
                        @click="changeTab(item.value)"
                    >
                        {{ item.label }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-span-9">
            <SearchForm
                ref="searchFormRefDom"
                @onReset="resetData"
                @onSearch="filterSearch"
            />

            <div class="mt-5 overflow-x-auto bg-white rounded-lg shadow-lg">
                <div class="overflow-x-auto">
                    <table class="myTable">
                        <TableHead
                            ref="tableHeadRefDom"
                            :labels="tableLabels"
                            @changeSort="changeSort"
                        />
                        <tbody>
                            <tr
                                v-for="(item, index) in datas"
                                :key="index"
                                class="text-center transition duration-200"
                            >
                                <td>{{ $subString(item.user.name, 10) }}</td>
                                <td>{{ item.user.phone }}</td>
                                <td>
                                    {{
                                        item.user.role === 0 ? "會員" : "服務商"
                                    }}
                                </td>
                                <td>{{ item.created_at | formatDateTime }}</td>
                                <td
                                    v-permission="['update']"
                                    class="cursor-pointer"
                                    @click="goToEdit(item.user_id)"
                                >
                                    <i class="far fa-edit"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="flex justify-center mt-3 mb-3">
                    <MyPagination
                        :pagination-data="paginationData"
                        :current-page="currentPage"
                        @onPageSizeChange="pageSizeChange"
                        @onCurrentPageChange="currentPageChange"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// 搜尋表單
import SearchForm from "./SearchForm.vue";
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// table Head
import TableHead from "@/components/table/TableHead.vue";
// 判斷是 empty 方法
import { isEmpty } from "@/service/anyService";
// import { nextTick } from "vue/types/umd";
const __sfc_main = {
  name: "salesman_verify"
};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const route = useRoute();
  // 取得全域 this 方法 ex: this.$api, this.$message
  const {
    proxy
  } = getCurrentInstance();

  // table head dom
  const tableHeadRefDom = ref(null);
  // 預設搜尋條件
  const defaultFilterOpts = {
    statuses: [parseInt(route.params.status)]
  };
  // 搜尋條件值
  const filterOpts = ref(defaultFilterOpts);
  // 整理搜尋條件
  const filterData = computed(() => {
    const filter = {};
    for (const key in filterOpts.value) {
      // 判斷 key 是否有值
      if (!isEmpty(filterOpts.value[key])) {
        filter[key] = filterOpts.value[key];
      }
    }
    filter.statuses = [parseInt(route.params.status)];
    const result = {
      ...filter,
      page: currentPage.value,
      limit: paginationData.value.limit
    };
    return result;
  });
  // table head 標題
  const tableLabels = ref([{
    label: "用戶 暱稱"
  }, {
    label: "手機"
  }, {
    label: "申請人身份"
  }, {
    label: "申請時間"
  }, {
    label: "查看"
  }]);
  // 搜尋表單 dom
  const loading = ref(false);
  // 搜尋表單 dom
  const searchFormRefDom = ref(null);
  // 分頁資料
  const paginationData = ref({
    limit: 10,
    total: 0
  });
  // 預設頁面
  const currentPage = ref(1);
  // 列表
  const datas = ref([{
    user: {
      name: ""
    },
    category: {
      name: ""
    }
  }]);
  // 選擇 tab
  const choseTab = ref([parseInt(route.params.status)]);
  // 審核狀態列表
  const tabs = ref([{
    label: "等待審核",
    value: [0]
  }, {
    label: "通過審核記錄",
    value: [1]
  }, {
    label: "未通過審核記錄",
    value: [-1]
  }]);
  /**
   * 選擇 tab 事件
   * @param { type Number(數字) } val 驗證狀態
   */
  function changeTab(val) {
    if (choseTab.value !== val) {
      router.push({
        name: "salesman_verify",
        params: {
          status: val[0]
        }
      });
    }
    choseTab.value = val;
    filterOpts.value = {
      statuses: val
    };
    searchFormRefDom.value.resetData();
  }

  /**
   * 更換每頁筆數
   * @param { type Number(數字) } val 每頁筆數
   */
  function pageSizeChange(val) {
    // 更新每頁取得幾筆資料參數
    paginationData.value.limit = val;
    // 當前頁面設定為第一頁
    currentPage.value = 1;
    getList();
  }

  /**
   * 分頁切換
   * @param { type Number(數字) } val 下一頁數字
   */
  function currentPageChange(val) {
    // 設定目前分頁為第幾面
    currentPage.value = val;
    getList();
  }

  /**
   * 取得列表資料
   */
  async function getList() {
    filterData.value.statuses = [route.params.status];
    loading.value = true;
    try {
      const {
        data
      } = await proxy.$api.GetApplyCityPlanSalesmanListApi(filterData.value);
      datas.value = data.data;
      currentPage.value = data.current_page;
      paginationData.value = {
        limit: data.per_page,
        total: data.total
      };
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "取得資料失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  /**
   * 跳轉編輯頁
   */
  function goToEdit(userId) {
    router.push({
      name: "salesman_verify_id",
      params: {
        status: route.params.status,
        id: userId
      }
    });
  }

  /**
   * 點擊重置按鈕事件
   * @param { type Boolean(布林值) } isChangeTab 判斷是否為切換 tab 當切換 tab 事件時 不觸發取得列表機制
   */
  async function resetData(isChangeTab = false) {
    filterOpts.value = defaultFilterOpts;
    currentPage.value = 1;
    tableHeadRefDom.value.reset();
    paginationData.value = {
      limit: 10,
      total: 0
    };
    // 判斷是否為切換 tab 當切換 tab 事件時 不觸發取得列表機制
    if (!isChangeTab) {
      await getList();
    }
  }
  // 點擊搜尋按鈕事件
  async function filterSearch(val) {
    currentPage.value = 1;
    filterOpts.value = val;
    await getList();
  }
  // 更換排序
  const changeSort = async val => {
    filterOpts.value.sort_by = val.sortBy;
    filterOpts.value.sort_type = val.orderBy;
    await getList();
  };
  watch(() => route.params.status, async (newV, oldV) => {
    if (newV !== undefined) {
      choseTab.value = [parseInt(newV)];
      await getList();
    }
  });
  onMounted(async () => {
    await getList();
  });
  return {
    tableHeadRefDom,
    tableLabels,
    searchFormRefDom,
    paginationData,
    currentPage,
    datas,
    choseTab,
    tabs,
    changeTab,
    pageSizeChange,
    currentPageChange,
    goToEdit,
    resetData,
    filterSearch,
    changeSort
  };
};
__sfc_main.components = Object.assign({
  SearchForm,
  TableHead,
  MyPagination
}, __sfc_main.components);
export default __sfc_main;
</script>


